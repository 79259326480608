// import { Link } from "react-router-dom";

import Screenshot from "./Screenshot";
import styles from "./Walkthrough.module.scss";

export default function Walkthrough() {
  return (
    <div className={styles["walkthrough"]}>
      <h1>Account Portal Walkthrough</h1>
      <p>
        Unlimited Academy uses MindBody as our scheduling, registration, and
        account management software. All information pertaining to student
        enrollment, attedance, and payment is available real time. You can use
        this portal to view your schedule, track your progress , and update your
        account information. In order to provide a transparent and current view
        into all aspects of a student's account, it is imperitive that a parent
        or guardian knows how to log in, keeps their information up to date, and
        is familiar with the information provided.
      </p>
      <p className={styles["callout"]}>
        Once you have set up your initial login information, you can access the
        Unlimited Academy Account Portal directly at
        <br />
        <b>
          <a href="https://clients.mindbodyonline.com/classic/ws?studioid=837606">
            https://clients.mindbodyonline.com/classic/ws?studioid=837606
          </a>
        </b>
        <br />
        The initial screen will allow you to login or reset your password.
      </p>
      <p>
        We understand many parents have MindBody accounts for their personal
        use. Please note that this is <b>NOT</b> the Unlimited Academy Portal.
        If you do have a MindBody account, you will still need to have an
        account at Unlimited. Once you have set up your account at Unlimited,
        our location can than be linked as a preferred location or "place you
        visit" in your MindBody profile. Please follow steps below to link your
        accounts.
      </p>
      <p>
        <ul>
          <li>
            Current students looking to update their account information can
            skip the Account setup instructions below and go directly to the{" "}
            <a href={"#current-accounts"}>Account Landing</a> section.
          </li>
          <li>
            Instructions for setting up a MindBody Account can be found{" "}
            <a href="https://support.mindbodyonline.com/s/article/How-do-I-create-a-Mindbody-account?language=en_US">
              here.
            </a>
          </li>
          <li>
            Instructions for setting up child accounts (Family Accounts) in
            MindBody can be found{" "}
            <a href="https://support.mindbodyonline.com/s/article/How-to-set-up-a-family-account-Family-Accounts?language=en_US">
              here.
            </a>
          </li>
        </ul>
      </p>
      <h3>Welcome Email</h3>
      <p>
        Upon enrollment at Unlimited Academy, you will recieve a welcome email
        from our registration system. This email will contain a link update your
        login information. Follow the instructions on the next 3 screens to
        create a user name and password.
      </p>
      <p>
        <a href="https://account.mindbodyonline.com/createaccount">
          https://account.mindbodyonline.com/createaccount
        </a>
      </p>
      <Screenshot
        imgSrc="/img/screens/01.jpg"
        imgAlt="Welcome Email from Unlimited Academy"
      />
      <Screenshot
        imgSrc="/img/screens/02.jpg"
        imgAlt="Create New Account Landing Page"
      />
      <Screenshot imgSrc="/img/screens/03.jpg" imgAlt="Create Your Account" />
      <h3>Verify Your Account</h3>
      <p>
        Once your account has been created, you will recieve an email asking you
        to verify the information you entered and set a password. Follow the
        link provided in the email.
      </p>
      <Screenshot imgSrc="/img/screens/04.jpg" imgAlt="Verificaiton Email" />
      <p>
        When you reach the confirmation page below, you will then see a prompt
        to follow in order to find our location.
      </p>
      <Screenshot
        imgSrc="/img/screens/05.jpg"
        imgAlt="Find Unlimited Academy"
      />
      <h3>Locate and Link Unlimited Academy to your MindBody Profile</h3>

      <p>
        The previous steps set up your account with MindBody. That login will
        allow you to access any businesses within the MindBody network. A few
        more steps are required to ensure that you link your profile to
        Unlimited Academy.
      </p>
      <p>
        The first step in the process is to locate our business within the
        network. There are two ways this looks, depending on how you access the
        page. Begin the process by ensuring the location box is checked and
        typing "Unlimited" or "Unlimited Tennis" in the search box.
      </p>
      <p>
        <a href="https://clients.mindbodyonline.com/launch">
          https://clients.mindbodyonline.com/launch
        </a>
      </p>
      <Screenshot imgSrc="/img/screens/06.jpg" imgAlt="Search Business" />
      <p>As you type, Unlimited Academy should show as an option.</p>
      <Screenshot
        imgSrc="/img/screens/07.jpg"
        imgAlt="Select Unlimited Academy"
      />
      <p>
        Click the entry for Unlimited and enter your login information. This
        version of the selection process will allow you to skip the sign in
        steps described below.
      </p>
      <Screenshot imgSrc="/img/screens/08.jpg" imgAlt="Direct Login" />
      <p>
        In some cases, you will get an alternate view of the search process.
        This typically happens on subsequent visits or if you have logged into a
        MindBody site before. The process is the same as described above. Begin
        by typing "Unlimited" in the search field.
      </p>
      <Screenshot imgSrc="/img/screens/09.jpg" imgAlt="Find Business" />
      <p>
        You will be presented with a list of options. If you have the refine
        location option enabled, Unlimited Academy should be first in the list.
      </p>
      <Screenshot imgSrc="/img/screens/10.jpg" imgAlt="Select Business" />
      <p>
        Once you select Unlimited from the list above, you will be taken to the
        our business page. Click the orange "Sign In" button in the middle.
      </p>
      <Screenshot
        imgSrc="/img/screens/11.jpg"
        imgAlt="Unlimited Academy Portal Landing Page"
      />
      <p>Enter your login information and select "Sign In".</p>
      <Screenshot
        imgSrc="/img/screens/12.jpg"
        imgAlt="Student Sign In"
        className={styles["alert"]}
      />
      <p>
        If you inadvertantly select "Staff Sign In" from the landing page, you
        will be taken to this screen. Select "Looking to book a service" to
        return to the student sign in.
      </p>
      <Screenshot imgSrc="/img/screens/13.jpg" imgAlt="Staff Login" />
      <h3 id="current-accounts">Account Landing Page</h3>
      <p>
        <a href="https://clients.mindbodyonline.com/consumermyinfo?studioid=837606">
          https://clients.mindbodyonline.com/consumermyinfo?studioid=837606
        </a>
      </p>

      <p>
        Once you have successfully logged in to Unlimited Academy, you will be
        brought to your Account Landing Page. Here you will see options to
        update you MindBody account information, information specific to
        Unlimited Academy, and billing information.
      </p>
      <Screenshot
        imgSrc="/img/screens/14.jpg"
        imgAlt="Academy Profile Landing Page"
      />
      <p>
        The first option will allow you to update your login information and
        MindBody profile.
      </p>
      <Screenshot
        imgSrc="/img/screens/15.jpg"
        imgAlt="Update your MindBody Profile"
      />
      <h3>Update Your Unlimited Academy Profile</h3>
      <p>
        Please make sure that your information and communication preferences are
        complete and accurate at Unlimited Academy.
      </p>
      <Screenshot
        imgSrc="/img/screens/16.jpg"
        imgAlt="Update Unlimited Academy Profile"
      />
      <Screenshot
        imgSrc="/img/screens/17.jpg"
        imgAlt="Academy Communications Preferences"
      />
      <h3>Update your Payment Information</h3>
      <p>
        As a security precaution, businesses in the MindBody network do not have
        access to payment information outside of their own location. Even if you
        have a payment method stored at MindBody, you will need to enter it
        here.
      </p>
      <Screenshot
        imgSrc="/img/screens/18.jpg"
        imgAlt="Academy billing info edit"
      />
      <h3>View Your Upcoming Schedule</h3>
      <p>
        Your Academy private and group schedule is displayed real-time in the
        "My Schedule" section, which is accessible from the top navigation bar.
        This schedule can be sent to your email by selecting the highlighted
        text above the grid. Please contact{" "}
        <a href="mailto:registration@unltd-academy.com">
          registration@unltd-academy.com
        </a>{" "}
        if anything in this schedule appears incorrect.
      </p>
      <Screenshot imgSrc="/img/screens/19.jpg" imgAlt="Your Schedule" />
      <h3>View Your Visit History</h3>
      <p>
        View your prior visits by selecting "Visit History" from the top
        navigation bar. This will show all prior appointments and allow you to
        see how each Pricing Option was applied to each visit. Please contact{" "}
        <a href="mailto:registration@unltd-academy.com">
          registration@unltd-academy.com
        </a>{" "}
        if anything appears incorrect in your visit history.
      </p>
      <Screenshot imgSrc="/img/screens/20.jpg" imgAlt="Visit History" />
      <h3>View Your Account Balances & Payments</h3>
      <p>
        Your account balance is displayed real-time by selecting "Account" from
        the top navigation bar. This will show all programs included in your
        enrollment (Pricing Options), your payment schedule, as well as any past
        purchases or payments made. Please contact{" "}
        <a href="mailto:registration@unltd-academy.com">
          registration@unltd-academy.com
        </a>{" "}
        if anything in the account section appears incorrect.
      </p>
      <Screenshot
        imgSrc="/img/screens/22.jpg"
        imgAlt="Account Balance & Payment History"
      />
      <hr />
      <h2>For Families with Multiple Students Enrolled</h2>
      <p>
        Families with multiple students enrolled will follow the same process in
        creating their account with Unlimited. The only difference is that a
        default account will be created for the guardian, and each student will
        be listed under them in a family account. In this case, MindBody
        information and billing information will be stored only once on the
        guardian account.
      </p>
      <p>
        To toggle between each family member, select the drop-down at the top
        right of the screen. You can then select the appropriate family account
        to view upcoming schedule and visit history.
      </p>
      <Screenshot imgSrc="/img/screens/23.jpg" imgAlt="Family Account Toggle" />
      <Screenshot imgSrc="/img/screens/24.jpg" imgAlt="Child Account View" />
      <h3> Viewing a Child's Schedule or Past Visits on the MindBody App</h3>
      <p className={styles["alert"]}>
        Unfortunately, as a current constraint of the sysetm, it is not possible
        to view a child's upcoming or past visits on the MindBody app. Please
        login to Unlimited Academy Portal as shown above to see this
        information. We do not currently recommend using the MindBody app.
      </p>
    </div>
  );
}
